import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useFormStore } from "../../Stores/Form";
import { AppMode, useRouterStore } from "../../Stores/Router";
import FormItem from "../FormItem/FormItem";
import FormLoading from "../FormLoading/FormLoading";
import FormProgressBar from "../FormProgressBar/FormProgressBar";
import FormSendButton from "../FormSendButton/FormSendButton";
import "./Form.scss";

function Form({ animated = true }: { animated?: boolean }) {
  const formStore = useFormStore();
  const routerStore = useRouterStore();
  const currentAction = formStore.action;
  const hasChatStyle = formStore?.action?.chatStyle ?? false;
  const canShowIntro =
    routerStore.appMode !== AppMode.SURVEY &&
    routerStore.appMode !== AppMode.SURVEY_FULL &&
    routerStore.appMode !== AppMode.SURVEY_WEB;

  if (
    !formStore.action ||
    !formStore.action.form ||
    formStore.sendingForm ||
    formStore.checkingDuplicates
  ) {
    return (
      <div className="form-loading">
        <FormLoading />
      </div>
    );
  }

  if (formStore.formSent) {
    return (
      <div className="form-sent">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle cx="26" cy="26" r="25" fill="none"></circle>
          <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
        </svg>
        <div className="form-sent-label">{formStore.action.thanksMessage}</div>
      </div>
    );
  }

  const renderError = () => {
    if (formStore.formError) {
      return <div className="form-error">{formStore.formError}</div>;
    }

    return null;
  };

  const renderFormItems = () => {
    return formStore.getCurrentFormItems().map((formItem: any) => {
      return (
        <CSSTransition
          key={formItem.name}
          classNames={`form-item-animation`}
          timeout={700}
        >
          <FormItem formItem={formItem} key={formItem.name} />
        </CSSTransition>
      );
    });
  };

  const renderFormContent = () => {
    return (
      <>
        {renderFormItems()}
        <FormSendButton />
      </>
    );
  };

  return (
    <>
      {renderError()}
      <div className="form-container-main">
        <FormProgressBar />
        {canShowIntro &&
          !hasChatStyle &&
          formStore.currentStep === 0 &&
          currentAction &&
          typeof currentAction.introMessage === "string" &&
          currentAction.introMessage.trim() && (
            <div className="form-intro">{currentAction.introMessage}</div>
          )}
        {animated ? (
          <TransitionGroup
            className={`form-container-animation--${formStore.navigationDirection}`}
          >
            {renderFormContent()}
          </TransitionGroup>
        ) : (
          renderFormContent()
        )}
      </div>
    </>
  );
}

export default Form;
