import { useTicketStore } from "../../Stores/Ticket";
import BotActionChatMessage from "../BotActionChatMessage/BotActionChatMessage";
import BotActionInputButtons from "../BotActionInputButtons/BotActionInputButtons";
import BotActionInputCalendly from "../BotActionInputCalendly/BotActionInputCalendly";
import BotActionInputDropdown from "../BotActionInputDropdown/BotActionInputDropdown";
import BotActionInputDropdownOld from "../BotActionInputDropdownOld/BotActionInputDropdownOld";
import BotActionInputMultiselect from "../BotActionInputMultiselect/BotActionInputMultiselect";
import BotActionInputText from "../BotActionInputText/BotActionInputText";
import BotActionInputTextArea from "../BotActionInputTextArea/BotActionInputTextArea";
import BotActionInputTextOld from "../BotActionInputTextOld/BotActionInputTextOld";
import BotActionInputUpload from "../BotActionInputUpload/BotActionInputUpload";
import BotActionRatingInput from "../BotActionRatingInput/BotActionRatingInput";
import ChatMessage from "../ChatMessage/ChatMessage";
import "./BotActionInput.scss";

function BotActionInput() {
  const feedbackItemStore = useTicketStore();
  const currentAction = feedbackItemStore.currentBotAction?.action;
  if (!currentAction) {
    return null;
  }

  const renderInputComponentForAction = () => {
    const attribute = currentAction?.attribute;
    if (!attribute?.type) {
      switch (currentAction.inputType) {
        case "text":
          return <BotActionInputTextOld action={currentAction} />;
        case "email":
          return <BotActionInputTextOld action={currentAction} />;
        case "number":
          return <BotActionInputTextOld action={currentAction} />;
        case "dropdown":
          return <BotActionInputDropdownOld action={currentAction} />;
        default:
          return <BotActionInputTextOld action={currentAction} />;
      }
    }

    switch (attribute.type) {
      case "TEXT":
        return <BotActionInputText action={currentAction} />;
      case "TEXTAREA":
        return <BotActionInputTextArea action={currentAction} />;
      case "EMAIL":
        return <BotActionInputText action={currentAction} />;
      case "NUMBER":
        return <BotActionInputText action={currentAction} />;
      case "DROPDOWN":
      case "SELECT":
        return <BotActionInputDropdown action={currentAction} />;
      case "MULTISELECT":
        return <BotActionInputMultiselect action={currentAction} />;
      case "UPLOAD":
        return <BotActionInputUpload action={currentAction} />;
      default:
        return <BotActionInputText action={currentAction} />;
    }
  };

  const renderContent = () => {
    switch (currentAction.type) {
      case "rateconversation":
        return (
          <BotActionChatMessage>
            <BotActionRatingInput action={currentAction} />
          </BotActionChatMessage>
        );
      case "input":
        return (
          <BotActionChatMessage>
            {renderInputComponentForAction()}
          </BotActionChatMessage>
        );
      case "calendly":
        return (
          <BotActionChatMessage>
            <BotActionInputCalendly action={currentAction} />
          </BotActionChatMessage>
        );
      case "answerbotflow":
        const answerBotReply = () => {
          if (feedbackItemStore.hasAnswerBotReply()) {
            return (
              <>
                {currentAction.askForAdditionalHelp &&
                  currentAction.askForAdditionalHelp.length > 0 && (
                    <ChatMessage
                      key="answerbot"
                      lastOfGroup={true}
                      chatMessage={{
                        data: {
                          content: currentAction.askForAdditionalHelp ?? "",
                          kai: true,
                        },
                        user: undefined,
                        session: undefined,
                        type: "BOT",
                      }}
                      showTime={true}
                    />
                  )}
              </>
            );
          }

          return (
            <ChatMessage
              key="answerbot"
              lastOfGroup={true}
              chatMessage={{
                data: {
                  content: currentAction?.question ?? "",
                },
                user: undefined,
                session: undefined,
                type: "BOT",
              }}
              showTime={true}
            />
          );
        };

        return (
          <>
            {answerBotReply()}
            <div className="bot-buttons">
              {currentAction.capabilities
                ?.filter((capability: any) => {
                  if (!capability?.showButton) return false;
                  
                  if (capability?.showButtonOnlyWhenNoAnswer) {
                    if (
                      !feedbackItemStore.lastMessageIsAIAnswer() ||
                      feedbackItemStore.lastMessageHasSources()
                    ) {
                      return false;
                    }
                  }

                  // Get the current bot response count
                  const responseCount = feedbackItemStore.getBotResponseCount();

                  // Show the button if:
                  // 1. showButtonAfterResponses is not set (null or undefined), or
                  // 2. The bot response count matches showButtonAfterResponses
                  return (
                    responseCount >= (capability?.showButtonAfterResponses ?? 1)
                  );
                })
                .map((capability: any, index: number) => {
                  return (
                    <button
                      key={index}
                      className="bot-button"
                      onClick={() => {
                        feedbackItemStore.answerBotAction(
                          {
                            action: capability?.id,
                            message: capability?.buttonText,
                          },
                          []
                        );
                      }}
                    >
                      {capability?.buttonText}
                    </button>
                  );
                })}
            </div>
          </>
        );
      case "buttons":
        return <BotActionInputButtons action={currentAction} />;
      default:
        return null;
    }
  };

  return <div className="bot-action-container">{renderContent()}</div>;
}

export default BotActionInput;
