import { useChecklistStore } from '../../Stores/Checklist';
import { useConfigStore } from '../../Stores/Config';
import { useHelpCenterStore } from '../../Stores/HelpCenter';
import { useNewsStore } from '../../Stores/News';
import { useTicketStore } from '../../Stores/Ticket';
import ChecklistCard from '../ChecklistCard/ChecklistCard';
import ConversationItem from '../ConversationItem/ConversationItem';
import HelpCenterSearch from '../HelpCenterSearch/HelpCenterSearch';
import NewsCard from '../NewsCard/NewsCard';
import WidgetMenuButton from '../WidgetMenuButton/WidgetMenuButton';
import "./WidgetMenu.scss";

function WidgetMenu() {
    const configStore = useConfigStore();
    const ticketStore = useTicketStore();
    const newsStore = useNewsStore();
    const checklistStore = useChecklistStore();
    const helpCenterStore = useHelpCenterStore();
    const latestConversation = ticketStore?.previousTickets?.[0];

    const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
        if (ticket.sessionNotificationsUnread) {
            return true;
        }
        return false;
    });

    const renderNews = () => {
        if (!newsStore.latestArticle) {
            return null;
        }

        return (
            <div className='widget-app-container' key="news">
                <div className='widget-app-container-body'>
                    <NewsCard news={newsStore.latestArticle} />
                </div>
            </div>
        );
    }

    const renderHelpCenterSearch = (label: string) => {
        return (
            <div className='widget-app-container' key="helpsearch">
                <div className='widget-app-container-body'>
                    <HelpCenterSearch label={label} articles={helpCenterStore.topArticles ?? []} />
                </div>
            </div>
        );
    }

    const renderChecklist = () => {
        if (checklistStore.checklists?.length <= 0) {
            return null;
        }

        const latestChecklist = checklistStore.checklists[0];
        return (
            <div className='widget-app-container' key="checklist">
                <div className='widget-app-container-body'>
                    <ChecklistCard checklist={latestChecklist} key={latestChecklist.id} />
                </div>
            </div>
        );
    }

    return (
        <div className='widget-menu'>
            {unreadTickets && unreadTickets.length > 0 ? (
                <div className='widget-unread-messages-container'>
                    {unreadTickets.slice(0, 2).map((ticket, index) => (
                        <ConversationItem 
                            ticket={ticket} 
                            index={index} 
                            key={ticket.bugId} 
                        />
                    ))}
                </div>
            ): latestConversation?.bugId && configStore?.config?.showLatestConversation && (
                <div className='widget-unread-messages-container'>
                    <ConversationItem 
                        index={0}
                        ticket={latestConversation} 
                        key={latestConversation.bugId} 
                    />
                </div>
            )}
            
            {renderChecklist()}
            {configStore.homeWidgets.map((widget, index) => {
                if (widget.type === "menu") {
                    return (
                        <div className='widget-menu-button-container' key={index}>
                            <div className='widget-menu-buttons'>
                                <WidgetMenuButton 
                                    menuItem={widget.config} 
                                    index={index} 
                                />
                            </div>
                        </div>
                    );
                }

                if (widget.type === "app") {
                    if (widget.config.app === "HELP_CENTER_SEARCH") {
                        return renderHelpCenterSearch(widget.config.label);
                    } else if (widget.config.app === "LATEST_NEWS") {
                        return renderNews();
                    }
                }

                return null;
            })}
        </div>
    );
}

export default WidgetMenu;
