import React from "react";
import "./AudioPlayer.scss";

interface AudioPlayerProps {
  audioSrc: string;
  autoPlay?: boolean;
}

const AudioPlayer = ({ audioSrc, autoPlay = false }: AudioPlayerProps) => {
  return (
    <audio
      className="audio-player"
      controls
      src={audioSrc}
      autoPlay={autoPlay}
    ></audio>
  );
};

export default AudioPlayer;
