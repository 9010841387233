import { GenericHttpClient } from "./config/http.generic.client";

export class HttpMessageService extends GenericHttpClient<any> {
  static _instance: HttpMessageService;
  static getInstance(): HttpMessageService {
    if (this._instance == null) {
      this._instance = new HttpMessageService("/v3/shared/messages");
    }
    return this._instance;
  }

  async rateMessage(args: { messageId: string; rating: string }): Promise<any> {
    const { messageId, rating } = args;

    return this.post<any>(`${this.path}/${messageId}/rate`, {
      rating: rating,
    });
  }
}
