import { FFmpeg } from '@ffmpeg/ffmpeg';

let ffmpeg: FFmpeg | null = null;

/**
 * Returns the shared FFmpeg instance, creating and loading it if needed.
 */
export async function getFFmpeg(): Promise<FFmpeg> {
  if (!ffmpeg) {
    ffmpeg = new FFmpeg();
    await ffmpeg.load(); // Load the WASM, etc.
  }
  return ffmpeg;
}