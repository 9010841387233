import { GenericHttpClient } from "./config/http.generic.client";

export class HttpTicketService extends GenericHttpClient<any> {
  static _instance: HttpTicketService;
  static getInstance(): HttpTicketService {
    if (this._instance == null) {
      this._instance = new HttpTicketService("/v3/shared/tickets");
    }
    return this._instance;
  }

  async indicateTyping(args: { ticketShareToken: string; typing: boolean }) {
    const { ticketShareToken, typing } = args;

    return this.post(`${this.path}/${ticketShareToken}/typing`, {
      typing,
    });
  }

  async answerWorkflow(args: { ticketShareToken: string; data: any }) {
    const { ticketShareToken, data } = args;

    return this.post(`${this.path}/${ticketShareToken}/workflow/answer`, {
      ...data,
    });
  }
}
