import { useState } from "react";
import { useEffect } from "react";
import FormLoading from "../../Components/FormLoading/FormLoading";
import { useAppViewStore } from "../../Stores/AppView";
import { useConfigStore } from "../../Stores/Config";
import { useRouterStore } from "../../Stores/Router";
import { ReactComponent as PlusIcon } from "../../Assets/plus-solid.svg";
import "./AppView.scss";
import { useSessionStore } from "../../Stores/Session";
import { handleGleapLink } from "../..";

function AppView() {
  const appViewStore = useAppViewStore();
  const routerStore = useRouterStore();
  const configStore = useConfigStore();
  const sessionStore = useSessionStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (routerStore.currentData) {
      if (appViewStore.currentApp?.url !== routerStore.currentData.url) {
        setLoading(true);
      }

      appViewStore.openAppInternally(routerStore.currentData);
    }
  }, [routerStore.currentData]);

  const messageHandler = (event: MessageEvent) => {
    if (
      appViewStore.currentApp?.url &&
      event.origin !== new URL(appViewStore.currentApp.url).origin
    ) {
      return;
    }
    
    if (event.data.type === "click") {
      if (event?.data?.data?.includes("gleap://")) {
        handleGleapLink(event.data.data, false);
        event.preventDefault();
        return;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [appViewStore.currentApp?.url]);

  if (!appViewStore.currentApp) {
    return null;
  }

  var showFeatureRequestButton = true;
  if (configStore.config.enabFeatReqBut !== undefined) {
    showFeatureRequestButton =
      configStore.config.enabFeatReqBut === "everyone" ||
      (configStore.config.enabFeatReqBut === "identified" &&
        sessionStore.session?.userId !== undefined);
  }

  return (
    <div
      className={`app-view ${loading && "app-view--loading"}`}
      key={appViewStore?.currentApp?.url}
    >
      {loading && <FormLoading />}
      <iframe
        title={appViewStore.currentApp.name}
        allowFullScreen
        src={appViewStore.currentApp.url}
        frameBorder="0"
        allow="autoplay; encrypted-media; fullscreen;"
        ref={(ref) => {
          if (ref !== null) {
            appViewStore.setAppFrame(ref);
          }
        }}
        onLoad={() => {
          setTimeout(() => {
            setLoading(false);
          }, 800);
        }}
      />
      {appViewStore.pageStack.length === 1 &&
        appViewStore.currentApp?.name === "Roadmap" &&
        appViewStore.appData.tab !== "roadmap" && (
          <div className="action-overlay">
            <div
              className="action-button form-send-button"
              onClick={() => {
                routerStore.setPage("flow", {
                  flowId: "featurerequests",
                });
              }}
            >
              <PlusIcon />
              {configStore.translateText("requestFeature")}
            </div>
          </div>
        )}
    </div>
  );
}

export default AppView;
