import { Node } from "@tiptap/core";

export const HelpcenterLinkExtension = Node.create({
  name: "helpCenterArticle",
  group: "block",
  atom: true,

  renderHTML({ HTMLAttributes }) {
    const { articleTitle, articleDescription, articleUrl, articleId } =
      HTMLAttributes;

    const html = `<div data-url="${articleUrl}" data-id="${articleId}" class="helpcenter-conversation-article"><div class="article-header-container"><div><div class="article-header">${articleTitle}</div></div></div><div class="article-description">${articleDescription} <a href="${articleUrl}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path d="M352 224l-46.5 0c-45 0-81.5 36.5-81.5 81.5c0 22.3 10.3 34.3 19.2 40.5c6.8 4.7 12.8 12 12.8 20.3c0 9.8-8 17.8-17.8 17.8l-2.5 0c-2.4 0-4.8-.4-7.1-1.4C210.8 374.8 128 333.4 128 240c0-79.5 64.5-144 144-144l80 0 0-61.3C352 15.5 367.5 0 386.7 0c8.6 0 16.8 3.2 23.2 8.9L548.1 133.3c7.6 6.8 11.9 16.5 11.9 26.7s-4.3 19.9-11.9 26.7l-139 125.1c-5.9 5.3-13.5 8.2-21.4 8.2l-3.7 0c-17.7 0-32-14.3-32-32l0-64zM80 96c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-48c0-17.7 14.3-32 32-32s32 14.3 32 32l0 48c0 44.2-35.8 80-80 80L80 512c-44.2 0-80-35.8-80-80L0 112C0 67.8 35.8 32 80 32l48 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L80 96z"/></svg></a></div></div>`;

    return ["helpcenterarticle", HTMLAttributes, html];
  },

  addAttributes() {
    return {
      articleId: {
        default: null,
      },
      articleTitle: {
        default: "No title",
      },
      articleDescription: {
        default: "No description",
      },
      articleUrl: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "helpcenterarticle",
        getAttrs: (node) => {
          if (typeof node === "string") {
            return {};
          }

          if (node.nodeType !== 1 /* Node.ELEMENT_NODE */) {
            return {};
          }

          const element = node as HTMLElement;
          return {
            articleId: element.getAttribute("articleId") || null,
            articleTitle: element.getAttribute("articleTitle") || "No title",
            articleDescription:
              element.getAttribute("articleDescription") || "No description",
            articleUrl: element.getAttribute("articleUrl") || null,
          };
        },
      },
    ];
  },

  renderText({ node }) {
    return `${node?.attrs?.articleTitle}\n${node?.attrs?.articleUrl}\n`;
  },
});
