import { useEffect, useRef } from "react";
import { useRouterStore } from "../../Stores/Router";
import { ReactComponent as Announcements } from "../../Assets/megaphone-regular.svg";
import { ReactComponent as AnnouncementsActive } from "../../Assets/megaphone-solid.svg";
import { ReactComponent as Roadmap } from "../../Assets/map-location-regular.svg";
import { ReactComponent as RoadmapActive } from "../../Assets/map-location-solid.svg";
import { ReactComponent as Tasks } from "../../Assets/check-done-01.svg";
import { ReactComponent as TasksActive } from "../../Assets/check-done-01-active.svg";
import { ReactComponent as Home } from "../../Assets/house-blank-regular.svg";
import { ReactComponent as HomeActive } from "../../Assets/house-blank-solid.svg";
import { ReactComponent as HelpCenter } from "../../Assets/circle-question.svg";
import { ReactComponent as HelpCenterActive } from "../../Assets/circle-question-solid.svg";
import { ReactComponent as Chat } from "../../Assets/message-regular.svg";
import { ReactComponent as ChatActive } from "../../Assets/message-solid.svg";
import { useFeatureRequestStore } from "../../Stores/FeatureRequests";
import { useConfigStore } from "../../Stores/Config";
import { useTicketStore } from "../../Stores/Ticket";
import { useHelpCenterStore } from "../../Stores/HelpCenter";
import { useAppViewStore } from "../../Stores/AppView";
import { useChecklistStore } from "../../Stores/Checklist";
import "./TabBar.scss";

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current as any;
};

function TabBar() {
  const routerStore = useRouterStore();
  const featureRequestStore = useFeatureRequestStore();
  const helpCenterStore = useHelpCenterStore();
  const configStore = useConfigStore();
  const ticketStore = useTicketStore();
  const appView = useAppViewStore();
  const checklistStore = useChecklistStore();
  const currentPage = routerStore.currentPage;

  const unreadTickets = ticketStore.previousTickets.filter((ticket) => {
    if (ticket.sessionNotificationsUnread) {
      return true;
    }
    return false;
  });

  const renderChecklistsTab = () => {
    if (
      configStore.config.hideChecklists ||
      checklistStore.checklists?.length === 0
    ) {
      return null;
    }

    const active =
      currentPage === "checklists" || currentPage === "checklistdetails";

    return (
      <div
        className={`tab-bar-item ${active && "active"}`}
        onClick={() => routerStore.setPage("checklists")}
      >
        <div className="icon-container">
          {active ? <TasksActive /> : <Tasks />}
          <div className="tab-bar-item--unread">
            {checklistStore.checklists?.length}
          </div>
        </div>
        <span>{configStore.config.checklistMenuLabel}</span>
      </div>
    );
  };

  const renderNewsTab = () => {
    if (configStore.config.hideNews) {
      return null;
    }

    const active = currentPage === "news" || currentPage === "newsdetails";

    return (
      <div
        className={`tab-bar-item ${active && "active"}`}
        onClick={() => routerStore.setPage("news")}
      >
        {active ? <AnnouncementsActive /> : <Announcements />}
        <span>{configStore.config.newsMenuLabel}</span>
      </div>
    );
  };

  const renderRoadmapTab = () => {
    if (configStore.config.hideFeatureRequests) {
      return null;
    }

    const active =
      currentPage === "appview" && appView.currentApp?.name === "Roadmap";

    return (
      <div
        className={`tab-bar-item ${active && "active"}`}
        onClick={() => {
          featureRequestStore.openFeatureRequest();
        }}
      >
        {active ? <RoadmapActive /> : <Roadmap />}
        <span>{configStore.config.featureRequestsMenuLabel}</span>
      </div>
    );
  };

  const renderConversations = () => {
    if (configStore.config.hideAllConversations) {
      return null;
    }

    const active =
      currentPage === "conversations" || currentPage === "conversation";

    return (
      <div
        className={`tab-bar-item ${active && "active"}`}
        onClick={() => routerStore.setPage("conversations")}
      >
        <div className="icon-container">
          {active ? <ChatActive /> : <Chat />}
          {unreadTickets && unreadTickets.length > 0 && (
            <div className="tab-bar-item--unread">
              {unreadTickets.length > 9 ? "9+" : unreadTickets.length}
            </div>
          )}
        </div>
        <span>{configStore.config.allConversationsMenuLabel}</span>
      </div>
    );
  };

  const renderHelpCenter = () => {
    if (configStore.config.hideKnowledgeBase) {
      return null;
    }

    const active =
      currentPage === "appview" && appView.currentApp?.name === "Help";

    return (
      <div
        className={`tab-bar-item ${active && "active"}`}
        onClick={() => {
          helpCenterStore.openHelpCenter({
            pushToHistory: false,
          });
        }}
      >
        <div className="icon-container">
          {active ? <HelpCenterActive /> : <HelpCenter />}
        </div>
        <span>{configStore.config.knowledgeBaseMenuLabel}</span>
      </div>
    );
  };

  if (
    configStore.config.hideNews &&
    configStore.config.hideFeatureRequests &&
    configStore.config.hideAllConversations &&
    configStore.config.hideKnowledgeBase
  ) {
    try {
      const homePage: HTMLElement | null =
        document?.body.querySelector(".home-page");
      if (homePage) {
        homePage.style.height = "100vh";
      }
    } catch (_) {}
    return <></>;
  }

  if (routerStore.history.length > 1) {
    return null;
  }

  const conversations = renderConversations();
  const news = renderNewsTab();
  const roadmap = renderRoadmapTab();
  const checklists = renderChecklistsTab();
  const helpCenter = renderHelpCenter();

  const activeTabsCount = [
    conversations,
    news,
    roadmap,
    checklists,
    helpCenter,
  ].filter((tab) => tab !== null).length;
  if (activeTabsCount === 0) {
    return null;
  }

  return (
    <>
      <div className={`tab-bar ${activeTabsCount <= 1 && "tab-bar--simple"}`}>
        <div
          className={`tab-bar-item ${currentPage === "menu" && "active"}`}
          onClick={() => routerStore.setPage("menu")}
        >
          {currentPage === "menu" ? <HomeActive /> : <Home />}
          <span>{configStore.translateText("homeLabel")}</span>
        </div>
        {conversations}
        {news}
        {roadmap}
        {checklists}
        {helpCenter}
      </div>
    </>
  );
}

export default TabBar;
