import Image from '@tiptap/extension-image';

export const CustomImageExtension = Image.extend({
  getAttrs: (dom: HTMLElement) => {
    if (!(dom instanceof HTMLElement)) {
      return {};
    }
    const style = dom.getAttribute('style') || '';
    const widthMatch = style.match(/width:\s*(\d+)px/);
    const maxWidthMatch = style.match(/max-width:\s*(\d+)px/);

    return {
      src: dom.getAttribute('src'),
      alt: dom.getAttribute('alt'),
      title: dom.getAttribute('title'),
      style: style,
      width: widthMatch ? widthMatch[1] : null,
      height: 'auto',
      maxWidth: maxWidthMatch ? maxWidthMatch[1] : null,
    };
  },
  addAttributes() {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      style: {
        default: null,
        parseHTML: (element) => {
          const style = element.getAttribute('style') || '';
          return style || null;
        },
      },
      width: {
        default: 'auto',
        parseHTML: (element) => {
          const style = element.getAttribute('style') || '';
          const widthMatch = style.match(/width:\s*(\d+)px/);
          return widthMatch ? widthMatch[1] : null;
        },
      },
      height: {
        default: 'auto',
        parseHTML: () => 'auto',
      },
      maxWidth: {
        default: '100%',
        parseHTML: (element) => {
          const style = element.getAttribute('style') || '';
          const maxWidthMatch = style.match(/max-width:\s*(\d+)px/);
          return maxWidthMatch ? maxWidthMatch[1] : null;
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    const attrs = { ...HTMLAttributes };
    let style = attrs.style || '';

    const widthMatch = style.match(/width:\s*(\d+)px/);
    if (widthMatch) {
      attrs.width = widthMatch[1];
    }

    const maxWidthMatch = style.match(/max-width:\s*(\d+)px/);
    if (!maxWidthMatch) {
      style = `max-width: 100%; ${style}`;
    }
    attrs.style = style;
    attrs.maxWidth = maxWidthMatch ? maxWidthMatch[1] : '100%';

    attrs.height = 'auto';

    const imgElement = ['img', attrs];
    const divElement = ['span', { style: `display: flex; width: 100%; padding: 5px 0px;` }, imgElement];

    return divElement as any;
  },
});

export default CustomImageExtension; 