import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { ReactComponent as SendLogo } from "../../Assets/arrow-up-solid.svg";
import { ReactComponent as StopIcon } from "../../Assets/circle-stop-regular.svg";
import { ReactComponent as MicrophoneIcon } from "../../Assets/microphone-solid.svg";
import { ReactComponent as TrashIcon } from "../../Assets/trash-can-regular.svg";
import { getFFmpeg } from "../../Helper/FFMpegLoader";
import { useConfigStore } from "../../Stores/Config";
import "./AudioRecorderUpload.scss";

interface AudioRecorderUploadProps {
  onAudioRecordingStart?: () => void;
  onAudioRecordingStop?: () => void;
  onSend?: (file: File) => void;
}

const AudioRecorderUpload = ({
  onAudioRecordingStart,
  onAudioRecordingStop,
  onSend,
}: AudioRecorderUploadProps) => {
  const config = useConfigStore();

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // For audio processing
  const [isSending, setIsSending] = useState(false); // For sending file
  const [rawFile, setRawFile] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const ffmpegRef = useRef<FFmpeg | null>(null);
  const isDeletingRef = useRef(false);

  useEffect(() => {
    getFFmpeg().then((ffmpeg) => {
      ffmpegRef.current = ffmpeg;
    });
  }, []);

  const startRecording = async () => {
    try {
      onAudioRecordingStart?.();
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mimeType = MediaRecorder.isTypeSupported("audio/mp4")
        ? "audio/mp4"
        : MediaRecorder.isTypeSupported("audio/webm")
        ? "audio/webm"
        : "";

      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        if (isDeletingRef.current) {
          isDeletingRef.current = false;
          return;
        }

        const audioBlob = new Blob(audioChunksRef.current, {
          type: mediaRecorderRef.current?.mimeType || "audio/wav",
        });
        setAudioURL(URL.createObjectURL(audioBlob));
        setLoading(true);

        try {
          const ffmpeg = ffmpegRef.current;
          if (ffmpeg) {
            const mimeType = mediaRecorderRef.current?.mimeType;
            const inputFileName =
              mimeType === "audio/webm"
                ? "input.webm"
                : mimeType === "audio/mp4"
                ? "input.mp4"
                : "input.wav";

            await ffmpeg.writeFile(inputFileName, await fetchFile(audioBlob));
            await ffmpeg.exec(["-i", inputFileName, "output.mp3"]);
            const mp3Data = await ffmpeg.readFile("output.mp3");
            const mp3Blob = new Blob([mp3Data], { type: "audio/mpeg" });
            const mp3File = new File([mp3Blob], "recorded-audio.mp3", {
              type: "audio/mpeg",
            });

            setRawFile(mp3File);
          }
        } catch (error) {
          console.error("Conversion or upload failed:", error);
        } finally {
          setLoading(false);
          audioChunksRef.current = [];
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error: any) {
      onAudioRecordingStop?.();
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        alert(
          error.message ??
            "Microphone access is denied. Please enable microphone permissions to record audio."
        );
      } else {
        console.error("Error accessing user's microphone:", error);
      }
    }
  };

  const stopRecording = (args: { shouldDelete?: boolean }) => {
    try {
      if (mediaRecorderRef.current) {
        if (args.shouldDelete) {
          isDeletingRef.current = true;
        }

        mediaRecorderRef.current.stop();
        setIsRecording(false);
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    } catch (_) {}

    if (args.shouldDelete) {
      setAudioURL(null);
      setRawFile(null);
      onAudioRecordingStop?.();

      setTimeout(() => {
        isDeletingRef.current = false;
      }, 100);
    }
  };

  const handleSend = async () => {
    if (isRecording) {
      stopRecording({ shouldDelete: false });
    }

    if (rawFile) {
      setIsSending(true); // Start sending process
      await sendFile();
    } else {
      setIsProcessing(true);
    }
  };

  useEffect(() => {
    if (rawFile && isProcessing) {
      sendFile();
    }
  }, [rawFile, isProcessing]);

  const sendFile = async () => {
    try {
      if (rawFile) {
        onSend?.(rawFile);
      }
    } catch (_) {
    } finally {
      setAudioURL(null);
      setRawFile(null);
      setIsProcessing(false);
      setIsSending(false); // Reset sending state
      onAudioRecordingStop?.();
    }
  };

  if (!config?.config?.audio) {
    return <></>;
  }

  if (audioURL) {
    return (
      <div className="audio-recorder-upload audio-recorder-upload--recording-paused">
        <TrashIcon
          className="audio-recorder-icon"
          onClick={() => {
            stopRecording({ shouldDelete: true });
          }}
        />
        <audio
          className="audio-recorder-player"
          controls
          src={audioURL}
        ></audio>
        <div onClick={handleSend} className="send-button">
          {!isSending ? (
            <SendLogo />
          ) : (
            <ReactLoading type="spin" color="#4a90e2" height={24} width={24} />
          )}
        </div>
      </div>
    );
  }

  if (isRecording) {
    return (
      <div className="audio-recorder-upload audio-recorder-upload--recording">
        <div className="audio-recorder-actions">
          <TrashIcon
            className="audio-recorder-icon"
            onClick={() => {
              stopRecording({ shouldDelete: true });
            }}
          />
          <div className="recording-text">
            {config.translateText("recording")}
          </div>
        </div>
        <div className="audio-recorder-actions">
          <StopIcon
            className="audio-recorder-icon audio-pause-icon recording-pulse"
            onClick={() => {
              stopRecording({ shouldDelete: false });
            }}
          />
          <div onClick={handleSend} className="send-button">
            <SendLogo />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="audio-recorder-upload">
      {!isRecording && !loading && (
        <MicrophoneIcon
          className="audio-recorder-icon"
          onClick={startRecording}
        />
      )}
    </div>
  );
};

export default AudioRecorderUpload;
