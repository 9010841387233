import { useSessionStore } from "../../../Stores/Session";

type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

interface RequestConfig {
  method?: HttpMethod;
  url: string;
  data?: any;
  headers?: Record<string, string | boolean>;
  params?: Record<string, any>;
}

export class HttpClientXHR {
  private baseURL: string;
  private defaultHeaders: Record<string, string | boolean>;

  constructor() {
    const { apiUrl } = useSessionStore.getState();
    this.baseURL = apiUrl || "https://dashapi.gleap.io";
    this.defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Credentials": true,
    };
  }

  private buildURL(url: string, params?: Record<string, any>): string {
    const fullUrl = `${this.baseURL}${url}`;
    if (params) {
      const query = Object.entries(params)
        .map(
          ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&");
      return fullUrl + (fullUrl.includes("?") ? "&" : "?") + query;
    }
    return fullUrl;
  }

  // Manually inject tokens and other custom headers into every request.
  private injectToken(
    headers: Record<string, string | boolean>
  ): Record<string, string | boolean> {
    const { session, sdkKey } = useSessionStore.getState();

    if (sdkKey) {
      headers["api-token"] = sdkKey;
    }

    if (session && session.gleapId) {
      headers["gleap-id"] = session.gleapId;
    }

    if (session && session.gleapHash) {
      headers["gleap-hash"] = session.gleapHash;
    }

    return headers;
  }

  request<T = any>(config: RequestConfig): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { method = "GET", url, data, headers, params } = config;
      const xhr = new XMLHttpRequest();
      const fullUrl = this.buildURL(url, params);

      xhr.open(method, fullUrl, true);

      // Merge default headers and custom headers then inject token info
      let allHeaders: Record<string, string | boolean> = {
        ...this.defaultHeaders,
        ...headers,
      };
      allHeaders = this.injectToken(allHeaders);

      Object.keys(allHeaders).forEach((key) => {
        xhr.setRequestHeader(key, String(allHeaders[key]));
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          let responseData;
          try {
            responseData = JSON.parse(xhr.responseText);
          } catch (e) {
            responseData = xhr.responseText;
          }
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(responseData);
          } else {
            reject({
              status: xhr.status,
              statusText: xhr.statusText,
              data: responseData,
            });
          }
        }
      };

      xhr.onerror = () => {
        reject(new Error("Network Error"));
      };

      if (data) {
        xhr.send(JSON.stringify(data));
      } else {
        xhr.send();
      }
    });
  }

  get<T = any>(
    url: string,
    config?: Omit<RequestConfig, "method" | "url">
  ): Promise<T> {
    return this.request<T>({ ...config, method: "GET", url });
  }

  post<T = any>(
    url: string,
    data?: any,
    config?: Omit<RequestConfig, "method" | "url" | "data">
  ): Promise<T> {
    return this.request<T>({ ...config, method: "POST", url, data });
  }

  put<T = any>(
    url: string,
    data?: any,
    config?: Omit<RequestConfig, "method" | "url" | "data">
  ): Promise<T> {
    return this.request<T>({ ...config, method: "PUT", url, data });
  }

  delete<T = any>(
    url: string,
    config?: Omit<RequestConfig, "method" | "url">
  ): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE", url });
  }
}
