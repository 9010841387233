import { HttpClientXHR } from "./http.client";

export class GenericHttpClient<T> extends HttpClientXHR {
  path: string;

  constructor(path: string) {
    super();
    this.path = path;
  }

  async findOne(args: { id: string; query?: any }): Promise<T> {
    return this.get<T>(`${this.path}/${args.id}`, { params: args.query });
  }

  async find(args: { query?: any }): Promise<T[]> {
    return this.get<T[]>(this.path, { params: args.query });
  }

  async findArchived(): Promise<T[]> {
    return this.get<T[]>(`${this.path}/archived`);
  }

  async search(args: { searchTerm: string }): Promise<T[]> {
    return this.get<T[]>(`${this.path}/search`, {
      params: { searchTerm: args.searchTerm },
    });
  }

  async create(args: { data: T; query?: any }): Promise<T> {
    return this.post<T>(this.path, args.data, { params: args.query });
  }

  async updateOne(args: { id: string; data: T; query?: any }): Promise<T> {
    return this.put<T>(`${this.path}/${args.id}`, args.data, {
      params: args.query,
    });
  }

  async deleteOne(args: { id: string }): Promise<T> {
    return this.delete<T>(`${this.path}/${args.id}`);
  }

  async archiveOne(args: { id: string }): Promise<T> {
    return this.put<T>(`${this.path}/${args.id}/archive`);
  }

  async unarchiveOne(args: { id: string }): Promise<T> {
    return this.put<T>(`${this.path}/${args.id}/unarchive`);
  }
}
