import { mergeAttributes, Node } from '@tiptap/core'
import { DOMOutputSpec } from '@tiptap/pm/model'

export interface ButtonOptions {
  HTMLAttributes: {
    [key: string]: any
  }
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    button: {
      setButton: (options: { href?: string, class?: string }) => ReturnType
    }
  }
}

export const ButtonExtension = Node.create<ButtonOptions>({
  name: 'editor-button',
  group: 'block',
  content: 'inline*',
  selectable: true,
  draggable: true,

  addOptions() {
    return {
      ...this.parent?.(),
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      href: {
        default: null,
      },
      class: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'div.editor-button',
        getAttrs: dom => dom instanceof HTMLElement ? {
          type: "editor-button"
        } : {}
      },
    ];
  },

  renderHTML({ HTMLAttributes }): DOMOutputSpec {
    return [
      'a',
      mergeAttributes({
        ...HTMLAttributes,
        class: `editor-button linktype-button ${HTMLAttributes.class}`,
        href: HTMLAttributes.href,
        target: "_blank",
      }),
      0
    ];
  },

  addCommands() {
    return {
      setButton:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              href: options.href,
              class: options.class,
            },
          })
        },
    }
  },
})
