import FormLoading from "../../Components/FormLoading/FormLoading";
import { useTicketStore } from "../../Stores/Ticket";
import { ReactComponent as ExclamationTriangleIcon } from "../../Assets/exclamation-triangle.svg";
import "./ConversationLoading.scss";
import { useRouterStore } from "../../Stores/Router";
import { useConfigStore } from "../../Stores/Config";

function ConversationLoading() {
  const { conversationError } = useTicketStore();
  const routerStore = useRouterStore();

  return (
    <div className="conversation">
      <div
        className="conversation-messages"
        style={{
          height: "100%",
        }}
      >
        <div className="conversation-messages-loading">
          {conversationError?.length ? (
            <div className="conversation-error">
              <div className="conversation-error-icon">
                <ExclamationTriangleIcon />
              </div>
              {conversationError}
              <button
                className="conversation-error-button"
                onClick={() => {
                  routerStore.popPage();
                }}
              >
                {useConfigStore.getState().translateText("kaiSubmitIdeaDone")}
              </button>
            </div>
          ) : (
            <FormLoading />
          )}
        </div>
      </div>
    </div>
  );
}

export default ConversationLoading;
