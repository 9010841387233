import { create } from "zustand";
import { useAppViewStore } from "./AppView";
import { useConfigStore } from "./Config";
import { useSessionStore } from "./Session";
import { useAppModalViewStore } from "./AppModalView";

interface FeatureRequestState {
  open: (asModal?: boolean) => void;
  openFeatureRequest: (shareToken?: string, asModal?: boolean) => void;
}

export const useFeatureRequestStore = create<FeatureRequestState>()(
  (set, get) => ({
    open: (asModal = false) => {
      get().openFeatureRequest(undefined, asModal);
    },
    openFeatureRequest: (shareToken?: string, asModal = false) => {
      const sessionStore = useSessionStore.getState();
      var details = "";
      if (shareToken && shareToken.length > 0) {
        details = `/${shareToken}`;
      }

      const language = useConfigStore.getState().getCurrentLanguage();

      const appUrl = `https://app.gleap.io/sharedboard/${sessionStore.sdkKey}/roadmap${details}?widgetApp=widget&lang=${language}&gleapId=${sessionStore.session?.gleapId}&gleapHash=${sessionStore.session?.gleapHash}`;

      const title = useConfigStore.getState().config.featureRequestsMenuLabel;
      if (asModal) {
        useAppModalViewStore.getState().openApp(appUrl, title);
      } else {
        useAppViewStore.getState().openApp(appUrl, "Roadmap", title, undefined);
      }
    },
  })
);
