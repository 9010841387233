import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as SendLogo } from "../../Assets/arrow-up-solid.svg";
import { ReactComponent as AttachmentButton } from "../../Assets/paperclip-solid.svg";
import { ReactComponent as XIcon } from "../../Assets/xmark-sharp-solid.svg";
import { resizeFile } from "../../Helper/ImageResize";
import { useConfigStore } from "../../Stores/Config";
import { useTicketStore } from "../../Stores/Ticket";
import AudioRecorderUpload from "../AudioRecorderUpload/AudioRecorderUpload";
import FormLoading from "../FormLoading/FormLoading";
import "./ChatMessageComposer.scss";

export const defaultAttachmentMessage = "📎 Attachment";

const allowedTypes = new Set([
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/plain",
]);

function ChatMessageComposer({
  sendMessage,
  onHeightUpdated,
  typingStatusChanged,
  answerBotIsActive = false,
  disabled = false,
}: {
  sendMessage: (message: string, attachments: File[]) => void;
  onHeightUpdated: (height: number) => void;
  typingStatusChanged?: (isTyping: boolean) => void;
  answerBotIsActive?: boolean;
  disabled?: boolean;
}) {
  const config = useConfigStore();
  const uploadingAttachment = useTicketStore(
    (store) => store.uploadingAttachment
  );
  const textareaRef = useRef(null as any);
  const imageResizerPickerRef = useRef(null as any);
  const [currentValue, setCurrentValue] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([] as any);
  const [audioRecordingIsActive, setAudioRecordingIsActive] = useState(false);

  const isDisabled =
    disabled ||
    !((currentValue && currentValue.length > 0) || selectedFiles.length > 0);

  const setFilesSmart = (files: File[]) => {
    if (!files || files.length === 0) {
      setSelectedFiles([]);
      textareaRef.current?.focus();
      return;
    }

    // Set the original file
    setSelectedFiles([files[0]]);

    // Resize the file and set it as the selected file.
    resizeFile(files[0], answerBotIsActive).then((resizedFile) => {
      setSelectedFiles([resizedFile]);
    });

    textareaRef.current?.focus();
  };

  const debouncedUserTypingPing = useCallback(
    debounce(
      (text: any) => {
        var isTyping = false;

        if (text && text.length > 0) {
          isTyping = true;
        }

        typingStatusChanged?.(isTyping);
      },
      800,
      {
        leading: true,
        trailing: false,
      }
    ),
    []
  );

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      var scrollHeight = textareaRef.current.scrollHeight;
      if (scrollHeight < 50) {
        scrollHeight = 50;
      }

      textareaRef.current.style.height = scrollHeight + "px";
      onHeightUpdated(scrollHeight);
    }
  }, [currentValue, audioRecordingIsActive]);

  const internalSendMessage = () => {
    if (isDisabled) {
      return;
    }

    sendMessage(
      currentValue && currentValue.length > 0
        ? currentValue
        : defaultAttachmentMessage,
      selectedFiles
    );
    setCurrentValue("");
    setFilesSmart([]);
    imageResizerPickerRef.current?.clear();
  };

  const handleFileInputClick = () => {
    var inputElement = document.createElement("input");
    inputElement.style.display = "none";
    if (document.body) {
      document.body.appendChild(inputElement);
    }
    inputElement.type = "file";
    inputElement.accept =
      ".json,.pdf,.csv,.txt,.xlsx,.mp4,.mp3,.mov,.webp,.webm,image/jpeg,image/png,image/jpg,image/webp,image/webm,image/gif";
    inputElement.multiple = false;
    inputElement.addEventListener("change", (arg) => {
      if (inputElement.files && inputElement.files.length > 0) {
        setFilesSmart(Array.from(inputElement.files));
      }

      document.body.removeChild(inputElement);
    });
    inputElement.dispatchEvent(new MouseEvent("click"));
  };

  const handlePasteCapture = (e: any) => {
    if (!e.clipboardData) {
      return;
    }

    const items = e.clipboardData.items;
    if (!items || items.length === 0) {
      return;
    }

    let files: File[] = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (
        item &&
        (item.type.startsWith("image") ||
          (!answerBotIsActive && allowedTypes.has(item.type)))
      ) {
        const blob = item.getAsFile();
        if (blob) {
          files.push(blob);
        }
      }
    }

    if (files.length > 0) {
      setFilesSmart(files);
      e.preventDefault();
    }
  };

  const onEnterPress = (e: any) => {
    const sendOnEnter = config.config?.sendOnEnter ?? true;
    if (sendOnEnter) {
      if (e.keyCode == 13 && e.shiftKey == false) {
        e.preventDefault();
        internalSendMessage();
      }
    } else {
      if (e.keyCode == 13 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        internalSendMessage();
      }
    }
  };

  const renderAttachment = () => {
    if (answerBotIsActive && !config.config?.vision) {
      return null;
    }

    if (uploadingAttachment) {
      return (
        <div className="attachment-loading">
          <FormLoading />
        </div>
      );
    }

    if (selectedFiles && selectedFiles.length > 0) {
      return null;
    }

    return (
      <AttachmentButton
        className="attachment-button"
        onClick={handleFileInputClick}
      />
    );
  };

  const renderAttachmentPreview = () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      return null;
    }

    const file = selectedFiles[0];
    const fileType = file?.type;

    return (
      <div className="chat-message-composer-chat-attachment">
        {fileType.includes("image") ? (
          <div className="chat-message-composer-chat-attachment-image">
            <img src={URL.createObjectURL(file)} alt="Attachment" />
            <div className="chat-message-composer-chat-attachment-image-remove">
              <XIcon
                onClick={() => {
                  setFilesSmart([]);
                  textareaRef.current?.focus();
                }}
              />
            </div>
          </div>
        ) : (
          <div className="chat-message-composer-chat-attachment-file">
            <AttachmentButton className="chat-message-composer-chat-attachment-file-icon" />
            <div className="chat-message-composer-chat-attachment-file-name">
              {file.name}
            </div>
            <XIcon
              className="chat-message-composer-chat-attachment-file-remove"
              onClick={() => {
                setFilesSmart([]);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="chat-message-composer-bg"></div>
      <div className="chat-message-composer">
        {!audioRecordingIsActive && (
          <div className="chat-message-composer-chat">
            <textarea
              value={currentValue}
              placeholder={config.translateText("writeReply")}
              ref={textareaRef}
              onChange={(e) => {
                setCurrentValue(e.target.value);
                debouncedUserTypingPing(e.target.value);
              }}
              onPasteCapture={handlePasteCapture}
              onKeyDown={onEnterPress}
            />
            {renderAttachmentPreview()}
          </div>
        )}
        <div
          className={
            !audioRecordingIsActive
              ? "composer-buttons"
              : "composer-buttons-audio-recording-active"
          }
        >
          {selectedFiles?.length > 0 ? null : (
            <AudioRecorderUpload
              onAudioRecordingStart={() => {
                setAudioRecordingIsActive(true);
              }}
              onAudioRecordingStop={() => {
                setAudioRecordingIsActive(false);
              }}
              onSend={(file: any) => {
                sendMessage(defaultAttachmentMessage, [file]);
              }}
            />
          )}
          {!audioRecordingIsActive && (
            <>
              {renderAttachment()}
              <div
                onClick={() => {
                  internalSendMessage();
                }}
                className={`send-button ${
                  isDisabled && `send-button--disabled`
                } `}
              >
                <SendLogo />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ChatMessageComposer;
