import { Node } from "@tiptap/react";

export const HidePlaceholderExtension = (name: string) => Node.create({
  name,

  group: "block",

  atom: true,

  draggable: true,

  renderHTML() {
    return [""];
  },
});

export default HidePlaceholderExtension;