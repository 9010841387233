import { create } from "zustand";
import Communicator from "../Helper/Communicator";
import { useRouterStore } from "./Router";
import { useHelpCenterStore } from "./HelpCenter";

interface AppViewState {
  currentApp?: {
    id: string;
    name: string;
    url: string;
  };
  onClose?: () => void;
  pageStack: {
    title: string;
    url?: string;
    showRoot?: boolean;
  }[];
  appData: any;
  appFrame?: HTMLIFrameElement;
  extendedView?: boolean;
  showExtendedViewTitle?: boolean;
  openApp: (
    url: string,
    name: string,
    title?: string,
    onClose?: () => void,
    rootUrl?: string
  ) => void;
  openAppInternally: (data: {
    url: string;
    name: string;
    title?: string;
    onClose?: () => void;
    rootUrl?: string;
  }) => void;
  replacePageTitle: (
    title: string,
    extendScreen?: boolean,
    url?: string
  ) => void;
  pushPage: (title: string, extendScreen?: boolean, url?: string) => void;
  getCurrentPage: () => string;
  getCurrentPageUrl: () => string | null | undefined;
  setAppData: (appData: any) => void;
  popPage: () => void;
  setAppFrame: (frame: HTMLIFrameElement) => void;
  setIsExtendedView: (extendedView: boolean) => void;
  setShowExtendedViewTitle: (showExtendedViewTitle: boolean) => void;
  reset(): void;
}

export const useAppViewStore = create<AppViewState>()((set, get) => ({
  currentApp: undefined,
  appFrame: undefined,
  extendedView: false,
  showExtendedViewTitle: false,
  appData: {},
  pageStack: [],
  setAppData: (appData: any) => {
    set({
      appData: appData,
      pageStack: [
        {
          title: "Roadmap",
        },
      ],
    });
  },
  reset() {
    set({
      pageStack: [],
      showExtendedViewTitle: false,
      extendedView: false,
    });
  },
  setIsExtendedView(extendedView) {
    if (extendedView) {
      Communicator.pageChanged("appextended");
    } else {
      Communicator.pageChanged("app");
    }

    set({
      extendedView,
    });
  },
  setShowExtendedViewTitle(showExtendedViewTitle) {
    set({
      showExtendedViewTitle: showExtendedViewTitle,
    });
  },
  openAppInternally: (data: {
    url: string;
    name: string;
    title?: string;
    onClose?: () => void;
    rootUrl?: string;
  }) => {
    var newStack: any[] = [];

    if (data?.rootUrl) {
      newStack.push({
        title: "Help",
        url: data?.rootUrl,
        showRoot: true,
      });
    }

    if (data?.title) {
      newStack.push({
        title: data?.title,
      });
    }
    
    set({
      currentApp: {
        id: data?.url,
        name: data?.name,
        url: data?.url,
      },
      pageStack: newStack,
      onClose: data?.onClose,
    });
  },
  openApp: (
    url: string,
    name: string,
    title?: string,
    onClose?: () => void,
    rootUrl?: string
  ) => {
    const appData = {
      url,
      name,
      title,
      rootUrl,
    };

    useRouterStore.getState().setPage("appview", appData);
  },
  replacePageTitle: (title: string, extendScreen = false, url?: string) => {
    get().setIsExtendedView(extendScreen);
    set({
      pageStack: [
        ...get().pageStack.slice(0, -1),
        {
          title,
          url,
        },
      ],
    });
  },
  pushPage: (title: string, extendScreen = false, url?: string) => {
    get().setIsExtendedView(extendScreen);

    if (
      get().pageStack.length > 0 &&
      get().pageStack[get().pageStack.length - 1]?.title === title
    ) {
      return;
    }

    set({
      showExtendedViewTitle: false,
      pageStack: [
        ...get().pageStack,
        {
          title,
          url,
        },
      ],
    });

  },
  popPage: () => {
    get().setIsExtendedView(false);
    get().setShowExtendedViewTitle(false);

    var stack = [...get().pageStack];
    stack.pop();
    set({
      pageStack: stack,
    });

    if (stack.length > 0) {
      // Get current stack item
      const currentStackItem = stack[stack.length - 1];

      if (currentStackItem?.showRoot) {
        useHelpCenterStore.getState().openHelpCenter({
          pushToHistory: false,
        });
      } else {
        if (get().appFrame) {
          get().appFrame?.contentWindow?.postMessage(
            JSON.stringify({ name: "goback" }),
            "*"
          );
        }
      }
    }
  },
  getCurrentPage: () => {
    if (get().pageStack.length > 0) {
      return get().pageStack[get().pageStack.length - 1]?.title;
    }

    return "";
  },
  getCurrentPageUrl: () => {
    if (get().pageStack.length > 0) {
      return get().pageStack[get().pageStack.length - 1]?.url;
    }

    return null;
  },
  setAppFrame: (frame: HTMLIFrameElement) => {
    if (get().appFrame === frame) {
      return;
    }

    set({
      appFrame: frame,
    });
  },
}));
